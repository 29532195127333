<template>
  <button
    class="rounded-md px-4 py-1.5 text-sm font-semibold disabled:cursor-default disabled:opacity-50"
    :class="`bg-${variant}-500 disabled:bg-${variant}-500 hover:bg-${variant}-600 focus:outline-none focus:ring focus:ring-${variant}-300 text-${textVariant}`"
    :disabled="disabled"
    :type="type"
    @click="command"
  >
    <div class="flex items-center justify-center gap-2">
      <component v-if="icon" class="h-4 w-4" :is="icon" />
      <span v-if="label">{{ t(`buttons.${label}`) }}</span>
    </div>
  </button>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  props: {
    command: {
      default: () => null,
      required: false,
      type: Function,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    icon: {
      required: false,
    },
    label: {
      required: false,
      type: String,
    },
    textVariant: {
      required: true,
      type: String,
      validator(value) {
        return ["black", "white"].includes(value);
      },
    },
    type: {
      default: "button",
      type: String,
    },
    variant: {
      required: true,
      type: String,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
