<template>
  <div class="flex h-full items-center justify-center">
    <div class="card w-5/6 p-4 sm:w-3/5 md:w-1/2 lg:w-2/5 xl:w-1/3">
      <h1 class="mb-4">{{ t("headings.verify") }}</h1>
      <Skeleton v-if="waiting" class="h-6 w-full" />
      <div v-else>
        <div v-if="success" class="flex items-center gap-2">
          <CheckCircleIcon class="h-6 w-6 text-green-500" />
          <p>{{ t("text.verification_success") }}</p>
        </div>
        <div v-else class="flex items-center gap-2">
          <XCircleIcon class="h-6 w-6 text-rose-600" />
          <p>{{ t("text.verification_error") }}</p>
        </div>
        <div class="text-right">
          <form-button
            :command="() => router.push('/sign-in')"
            :disabled="waiting"
            id="sign-in-button"
            :label="t('sign_in')"
            textVariant="white"
            type="button"
            variant="indigo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";

export default {
  components: { CheckCircleIcon, FormButton, Skeleton, XCircleIcon },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const success = ref(false);

    onMounted(() => {
      const token = route.params.token;
      if (route.path.includes("email")) {
        store.dispatch("authentication/verifyEmail", token);
      } else {
        store.dispatch("authentication/verifyPassword", token);
      }
    });

    watch(
      () => store.state.authentication.status,
      (status) => {
        if (status.success) {
          success.value = true;
        }
      },
      { deep: true }
    );

    return {
      router,
      success,
      t,
      waiting: computed(() => store.state.authentication.waiting),
    };
  },
};
</script>
